import styles from './OrganoidInfoTab.module.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DraftModal from '../../components/modal/DraftModal';
import LargeFileUploads from '../../components/modal/LargeFileUploads';
import MultiLargeFileUploads from '../../components/modal/MultiLargeFileUploads';

function OrganoidInfoTab() {
    const { t } = useTranslation();
    const { tmpKey } = useParams();

    const [isUploadModal, setIsUploadModal] = useState(false);

    const [draftOpinion, setDraftOpinion] = useState("");

    const [organoidInfo, setOrganoidInfo] = useState({});
    const [isEditmode, setIsEditMode] = useState(false);

    const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);

    const hashmarkToSharp = (word) => {
        if (!word) return "";
        return word.replace('hashmark', '#');
    }

    const clickUploadModalOpen = () => {
        setIsUploadModal(!isUploadModal);
    }

    const clickUploadModalClose = () => {
        setIsUploadModal(false);
    }

    const [formData, setFormData] = useState({
        Key: "",
        SampleType: "",
        Organism: "",
        Type: "",
        Subtype: "",
        Orgin: "",
        Pair: "",
        establishmentNo: "",
        MutationInformation: "",
        surgeryDate: "",
        WES: "",
        RNASeq: "",
        MSS: "",
        sampleSource: "",
        site: "",
    });

    const handleEditClick = () => {
        setIsEditMode(!isEditmode);
    }

    const handleSaveClick = () => {
        setIsDraftModalOpen(true);
    }

    const draftFormData = () => { }

    const closeDraftModal = () => {
        setIsDraftModalOpen(false);
    }

    const saveDraft = (draft) => {
        setDraftOpinion(draft);  // draftOpinion 상태 업데이트
        setIsDraftModalOpen(false);  // 모달 닫고 데이터 저장 로직 실행
    }

    useEffect(() => {
        if (draftOpinion !== "") {
            const updatedFormData = {
                ...formData,
                draftOpinion
            };
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(updatedFormData)
            }
            fetch('/api/updateOraganoidInfo', reqOption)
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    setIsEditMode(false);
                });
        }
    }, [draftOpinion]);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: tmpKey,
            })
        }

        fetch("/api/getOrganoidInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                setOrganoidInfo(data);
            });
    }, [tmpKey]);

    useEffect(() => {
        if (organoidInfo[0]) {
            setFormData({
                Key: organoidInfo[0].Key || "",
                SampleType: organoidInfo[0].SampleType || "",
                Organism: organoidInfo[0].Organism || "",
                Type: organoidInfo[0].Type || "",
                Subtype: organoidInfo[0].Subtype || "",
                Orgin: organoidInfo[0].Orgin || "",
                Pair: organoidInfo[0].Pair || "",
                establishmentNo: organoidInfo[0].establishmentNo || "",
                MutationInformation: organoidInfo[0].MutationInformation || "",
                surgeryDate: organoidInfo[0].surgeryDate || "",
                WES: organoidInfo[0].WES || "",
                RNASeq: organoidInfo[0].RNASeq || "",
                MSS: organoidInfo[0].MSS || "",
                sampleSource: organoidInfo[0].sampleSource || "",
                site: organoidInfo[0].site || "",
            });
        }
    }, [organoidInfo]);

    return (
        <div className={styles.outerBox}>
            {
                isDraftModalOpen &&
                <DraftModal onClose={closeDraftModal} onSubmit={saveDraft} />
            }
            <div className={styles.basicInformation}>
                <span className={styles.title}>
                    Basic Information
                </span>
                <span className={styles.editButton} onClick={() => { handleEditClick() }}>
                    edit
                </span>
                {
                    isEditmode &&
                    <span className={styles.editButton} onClick={() => { handleSaveClick() }}>
                        save
                    </span>
                }
            </div>
            <div className={styles.contents}>
                <div className={styles.leftContent}>GBCC Code</div>
                <div className={styles.rightContent}>{hashmarkToSharp(organoidInfo[0]?.Key)}</div>

                <div className={styles.leftContent}>Disease type</div>
                {
                    isEditmode ? <input value={formData.Type} onChange={(e) => setFormData({ ...formData, Type: e.target.value })} className={styles.rightContent} />
                        : <div className={styles.rightContent}>{organoidInfo[0]?.Type}</div>
                }

                <div className={styles.leftContent}>Subtype</div>
                {
                    isEditmode ? <input value={formData.Subtype} onChange={(e) => setFormData({ ...formData, Subtype: e.target.value })} className={styles.rightContent} />
                        : <div className={styles.rightContent}>{organoidInfo[0]?.Subtype}</div>
                }

                <div className={styles.leftContent}>Sample source</div>
                {
                    isEditmode ? (
                        <select value={formData.sampleSource ?? organoidInfo[0]?.sampleSource ?? ""} onChange={(e) => setFormData({ ...formData, sampleSource: e.target.value })} className={styles.rightContent}>
                            <option value="">-</option>
                            <option value="Surgical tissue">Surgical tissue</option>
                            <option value="Biopsy tissue">Biopsy tissue</option>
                            <option value="Ascites">Ascites</option>
                            <option value="Pleural effusion">Pleural effusion</option>
                            <option value="Pericardial effusion">Pericardial effusion</option>
                            <option value="PDX">PDX</option>
                        </select>
                    ) : (
                        <div className={styles.rightContent}>{organoidInfo[0]?.sampleSource || "-"}</div>
                    )
                }

                <div className={styles.leftContent}>Sample type</div>
                {
                    isEditmode ? (
                        <select value={formData.SampleType ?? organoidInfo[0]?.SampleType ?? ""} onChange={(e) => setFormData({ ...formData, SampleType: e.target.value })} className={styles.rightContent}>
                            <option value="">-</option>
                            <option value="Metastasis">Metastasis</option>
                            <option value="Primary">Primary</option>
                        </select>
                    ) : (
                        <div className={styles.rightContent}>{organoidInfo[0]?.SampleType}</div>
                    )
                }

                <div className={styles.leftContent}>Site</div>
                {
                    isEditmode ? <input value={formData.site} onChange={(e) => setFormData({ ...formData, site: e.target.value })} className={styles.rightContent} />
                        : <div className={styles.rightContent}>{organoidInfo[0]?.site}</div>
                }

                <div className={styles.leftContent}>Sugery date</div>
                {
                    isEditmode ? <input value={formData.surgeryDate} onChange={(e) => setFormData({ ...formData, surgeryDate: e.target.value })} className={styles.rightContent} />
                        : <div className={styles.rightContent}>{organoidInfo[0]?.surgeryDate}</div>
                }

                <div className={styles.leftContent}>Establishment no.</div>
                {
                    isEditmode ? <input value={formData.establishmentNo} onChange={(e) => setFormData({ ...formData, establishmentNo: e.target.value })} className={styles.rightContent} />
                        : <div className={styles.rightContent}>{organoidInfo[0]?.establishmentNo}</div>
                }

                <div className={styles.leftContent}>N/T pair</div>
                {
                    isEditmode ? (
                        <select value={formData.Pair ?? organoidInfo[0]?.Pair ?? ""} onChange={(e) => setFormData({ ...formData, Pair: e.target.value })} className={styles.rightContent}>
                            <option value="">-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    ) : (
                        <div className={styles.rightContent}>{organoidInfo[0]?.Pair}</div>
                    )
                }

                <div className={styles.leftContent}>WES</div>
                {
                    isEditmode ? (
                        <select value={formData.WES ?? organoidInfo[0]?.WES ?? ""} onChange={(e) => setFormData({ ...formData, WES: e.target.value })} className={styles.rightContent}>
                            <option value="">-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    ) : (
                        <div className={styles.rightContent}>{organoidInfo[0]?.WES}</div>
                    )
                }

                <div className={styles.leftContent}>RNA seq.</div>
                {
                    isEditmode ? (
                        <select value={formData.RNASeq ?? organoidInfo[0]?.RNASeq ?? ""} onChange={(e) => setFormData({ ...formData, RNASeq: e.target.value })} className={styles.rightContent}>
                            <option value="">-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    ) : (
                        <div className={styles.rightContent}>{organoidInfo[0]?.RNASeq}</div>
                    )
                }


                <div className={styles.leftContent}>Mutation information</div>
                {
                    isEditmode ? <textarea value={formData.MutationInformation} onChange={(e) => setFormData({ ...formData, MutationInformation: e.target.value })} className={styles.rightContent} />
                        : (
                            <div className={styles.rightContent}>
                                {organoidInfo[0]?.MutationInformation?.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        )
                    // <div className={styles.rightContent}>{organoidInfo[0]?.MutationInformation}</div>
                }

                <div className={styles.leftContent}>PDO annotation</div>
                {
                    isEditmode ? <textarea value={formData.MSS} onChange={(e) => setFormData({ ...formData, MSS: e.target.value })} className={styles.rightContent} />
                        : (
                            <div className={styles.rightContent}>
                                {organoidInfo[0]?.MSS?.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        )
                    // <div className={styles.rightContent}>{organoidInfo[0]?.MSS}</div>
                }
            </div>
            {
                organoidInfo[0]?.RNASeq.toLowerCase() === 'no' && (
                    <div onClick={clickUploadModalOpen} className={styles.uploadButton}>
                        Raw data upload
                    </div>
                )
            }
            {
                // isUploadModal && <LargeFileUploads onClose={clickUploadModalClose} />
                isUploadModal && <MultiLargeFileUploads onClose={clickUploadModalClose} />
            }
        </div >
    );
}

export default OrganoidInfoTab;
