// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrugResponsePlot_Fixed_chartContainer__M2K5O {
    width: 80vw; /* 부모 요소에 맞춘 너비 */
    height: 50vh; /* 부모 요소에 맞춘 높이 */
    max-width: 100%; /* 최대 너비 제한 */
    max-height: 100%; /* 최대 높이 제한 */
    position: relative; /* 자식 요소의 절대 위치 조정을 위한 설정 */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/graphComponents/DrugResponsePlot_Fixed.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,iBAAiB;IAC9B,YAAY,EAAE,iBAAiB;IAC/B,eAAe,EAAE,aAAa;IAC9B,gBAAgB,EAAE,aAAa;IAC/B,kBAAkB,EAAE,2BAA2B;EACjD","sourcesContent":[".chartContainer {\n    width: 80vw; /* 부모 요소에 맞춘 너비 */\n    height: 50vh; /* 부모 요소에 맞춘 높이 */\n    max-width: 100%; /* 최대 너비 제한 */\n    max-height: 100%; /* 최대 높이 제한 */\n    position: relative; /* 자식 요소의 절대 위치 조정을 위한 설정 */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": `DrugResponsePlot_Fixed_chartContainer__M2K5O`
};
export default ___CSS_LOADER_EXPORT___;
