import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { calculateIC50 } from './IC50_cal';
import * as d3 from 'd3-scale-chromatic';
import numeric from 'numeric';

ChartJS.register(...registerables);

// 에러바를 그리는 플러그인 정의
const errorBarPlugin = {
  id: 'errorBarPlugin',
  afterDatasetsDraw: (chart, args, options) => {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, index) => {
      const meta = chart.getDatasetMeta(index);
      if (!meta.hidden && dataset.errorBars) {
        meta.data.forEach((point, i) => {
          const error = dataset.errorBars[i];
          if (error) {
            const x = point.x;
            const yMin = chart.scales.y.getPixelForValue(error.yMin);
            const yMax = chart.scales.y.getPixelForValue(error.yMax);

            ctx.beginPath();
            ctx.moveTo(x, yMin);
            ctx.lineTo(x, yMax);
            ctx.strokeStyle = dataset.borderColor;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x - 5, yMin);
            ctx.lineTo(x + 5, yMin);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x - 5, yMax);
            ctx.lineTo(x + 5, yMax);
            ctx.stroke();
          }
        });
      }
    });
  }
};

// 색상 팔레트를 사용하여 고르게 분포된 색상 생성
const generateColors = (numColors) => {
  return d3.schemeCategory10.concat(d3.schemeSet1, d3.schemeSet2, d3.schemeSet3).slice(0, numColors);
};

const ResponsePlot = ({ data }) => {
  const colors = generateColors(100); // 100가지 색상 생성

  const datasets = Object.keys(data).map((chemical, index) => {
    const xdata = data[chemical].xdata;
    const ydata = data[chemical].ydata;

    const result = calculateIC50(xdata, ydata);
    const solution = result.solution;

    // xModel을 100점에서 20점으로 줄임
    const xModel = numeric.linspace(-3, 10, 20);
    const yModel = xModel.map(x => result.model(x, solution[0], solution[1]));

    return {
      label: `${chemical} IC50: ${solution[0].toFixed(2)}`,  // 범례에 IC50 값 포함
      data: xModel.map((x, i) => ({
        x: x,
        y: yModel[i]
      })),
      borderColor: colors[index % colors.length], // 고유한 색상 할당
      backgroundColor: colors[index % colors.length],
      fill: false,
      pointRadius: 0, // 선 위의 점을 보이지 않도록 설정
      pointBackgroundColor: colors[index % colors.length],
    };
  });

  // 실제 데이터 포인트를 추가하여 강조
  const actualDataSets = Object.keys(data).map((chemical, index) => {
    const errorBars = data[chemical].ydata.map(y => ({
      yMin: y * 0.9,  // 에러바의 하한값 (예: ±10%)
      yMax: y * 1.1   // 에러바의 상한값
    }));
    
    return {
      label: '',
      data: data[chemical].xdata.map((x, i) => ({
        x: x,
        y: data[chemical].ydata[i],
      })),
      errorBars: errorBars, // 에러바 데이터 추가
      borderColor: colors[index % colors.length],
      backgroundColor: colors[index % colors.length],
      fill: false,
      pointRadius: 6, // 실제 데이터 포인트에 더 큰 점을 표시
      pointBackgroundColor: colors[index % colors.length],
      showLine: false, // 실제 데이터는 선으로 연결하지 않음
    };
  });

  const chartData = {
    datasets: [...datasets, ...actualDataSets],
  };

  const options = {
    scales: {
      x: {
        type: 'linear',
        min: -3,
        max: 10,
        title: {
          display: true,
          text: 'Concentration log10(nM)'
        }
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Cell Viability (%)'
        }
      }
    },
    plugins: {
      errorBarPlugin, // 커스텀 에러바 플러그인 추가
      legend: {
        position: 'right',
        labels: {
          filter: function(item, chart) {
            return item.text !== ''; // 범례에 라벨이 없는 항목은 표시하지 않음
          }
        }
      },
      title: {
        display: true,
        text: 'Drug Response Line Plot'
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const yValue = tooltipItem.raw.y;
            return typeof yValue === 'number'
              ? `x: ${tooltipItem.raw.x.toFixed(2)}, y: ${yValue.toFixed(2)}`
              : `x: ${tooltipItem.raw.x}, y: ${yValue}`;
          }
        }
      }
    }
  };

  return (
    <>
      <Line data={chartData} options={options} />
    </>
  );
};

export default ResponsePlot;