import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './DBInputModal.module.css';

//이미지 임포트
import download_icon from "../../img/common/download_icon.svg";
import upload_icon from "../../img/common/upload_icon.svg";

function DBInputModal(props) {
    const { t } = useTranslation();
    const [fileData, setFileData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Select');
    const [alertMsg, setAlertMsg] = useState({ message: '', type: 'danger' });
    const [imageFiles, setImageFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]); // IC50 관련 파일 업로드를 위한 상태 변수
    const [tmp, setTmp] = useState('');
    const [isUploading, setIsUploading] = useState(false); // 업로드 상태 추가

    //url 매핑
    const apiUrlMap = {
        'Organoid summary': '/api/setOrganoidInfo',
        'Patient summary': '/api/setPatientInfo',
        '비공개용 Patient info(stomach)': '/api/setPrivatePatientInfo',
        'Pathology_Picture_picture_data': '/api/setPathologyPicturePictureData',
        'Light_Microscope_picture_data': '/api/setLightMicroscopePictureData',
        'Banking master stock': '/api/setBankingInfo',
        'IC50 Graph data': '/api/setGraphData',
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleImageUpload = (e) => {
        const newFiles = Array.from(e.target.files);
        setImageFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    // 파일 리스트에서 선택된 파일 제거
    const removeSelectedFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };


    const handleImageSubmit = async () => {
        setIsUploading(true); // 업로드 시작
        try {
            const apiUrl = apiUrlMap[selectedOption];
            const formData = new FormData();
            console.log(apiUrl);

            for (let i = 0; i < imageFiles.length; i++) {
                formData.append('images', imageFiles[i]);
            }
            const res = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (res.data !== '') {
                const alertType = res.data === 'Success' ? 'success' : 'danger';
                setAlertMsg({ message: res.data, type: alertType });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsUploading(false); // 업로드 완료
        }
    };

    const processMergedCells = (worksheet) => {
        const mergedCells = worksheet['!merges'] || [];
        mergedCells.forEach((range) => {
            const s = range.s; // 시작 셀
            const e = range.e; // 끝 셀
            const startCellRef = XLSX.utils.encode_cell(s);
            const mainCellValue = worksheet[startCellRef];

            if (!mainCellValue) {
                console.warn(`No value found in the starting cell of a merged range at ${startCellRef}. Skipping this range.`);
                return; // 셀에 값이 없으면 이후 작업을 수행하지 않고 반환
            }

            for (let R = s.r; R <= e.r; ++R) {
                for (let C = s.c; C <= e.c; ++C) {
                    if (R !== s.r || C !== s.c) {
                        const targetCellRef = XLSX.utils.encode_cell({ r: R, c: C });
                        worksheet[targetCellRef] = {
                            v: mainCellValue.v,
                            t: mainCellValue.t,
                            w: mainCellValue.w
                        };
                    }
                }
            }
        });
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const workbook = XLSX.read(bstr, { type: 'binary', cellDates: true }); // cellDates: true 옵션 추가
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            processMergedCells(worksheet);
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '', raw: true });
            data.forEach((row) => {
                row.forEach((cell, index) => {
                    if (cell instanceof Date) {
                        row[index] = formatDate(cell);
                    }
                });
            });
            setFileData(data);
        };

        reader.readAsBinaryString(file);
    };

    // 다중 파일 처리 로직 추가
    const handleFilesUpload = (e) => {
        const files = Array.from(e.target.files);

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const workbook = XLSX.read(bstr, { type: 'binary', cellDates: true });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                processMergedCells(worksheet);
                const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '', raw: true });

                data.forEach((row) => {
                    row.forEach((cell, index) => {
                        if (cell instanceof Date) {
                            row[index] = formatDate(cell);
                        }
                    });
                });

                setSelectedFiles((prevFiles) => [...prevFiles, { fileName: file.name, data }]);
            };

            reader.readAsBinaryString(file);
        });
    };

    const handleSubmit = async () => {
        try {
            const apiUrl = apiUrlMap[selectedOption];
            if (selectedOption === 'IC50 Graph data') {
                const promises = selectedFiles.map(async (file) => {
                    const res = await axios.post(apiUrl, file);  // file 객체 전체를 전송
                    return res.data;
                });

                const results = await Promise.all(promises);
                const success = results.every((result) => result === 'Success');
                setAlertMsg({ message: success ? 'All files uploaded successfully' : 'Some files failed to upload', type: success ? 'success' : 'danger' });
            } else {
                const res = await axios.post(apiUrl, { data: fileData });
                if (res.data !== '') {
                    const alertType = res.data === 'Success' ? 'success' : 'danger';
                    setAlertMsg({ message: res.data, type: alertType });
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleDownload = () => {
        const fileMap = {
            'Organoid summary': 'OrganoidSummary.xlsx',
            'Patient summary': 'PatientSummary.xlsx',
            'Banking master stock': 'banking_form.xlsx',
            'IC50 Graph data': 'graphData_form.xlsx',
        };

        const fileName = fileMap[selectedOption];

        if (fileName) {
            const link = document.createElement('a');
            link.href = process.env.PUBLIC_URL + '/files/' + fileName;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            alert('엑셀 파일 양식을 지원하지 않습니다.');
        }
    };

    return (
        <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
            <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <div className={styles.modalHeaderName}>
                        {t('DB Input')}
                    </div>
                    <div onClick={props.onClose} className={styles.closeButton}>
                        X
                    </div>
                </div>
                {alertMsg.message && (
                    <Alert variant={alertMsg.type} onClose={() => setAlertMsg({ message: "", type: "danger" })} dismissible className={styles.error}>
                        <pre>{alertMsg.message}</pre>
                    </Alert>
                )}
                <div>
                    <div className={styles.selectBox}>
                        <select value={selectedOption} onChange={handleSelectChange}>
                            <option>Select</option>
                            <option>Organoid summary</option>
                            <option>Patient summary</option>
                            <option>비공개용 Patient info(stomach)</option>
                            <option>Pathology_Picture_picture_data</option>
                            <option>Light_Microscope_picture_data</option>
                            <option>Banking master stock</option>
                            <option>IC50 Graph data</option>
                        </select>
                    </div>
                    {selectedOption === 'Pathology_Picture_picture_data' || selectedOption === 'Light_Microscope_picture_data' ? (
                        <>
                            <input type="file" accept="image/*" multiple onChange={handleImageUpload} />
                            <button onClick={handleImageSubmit}>
                                <img src={upload_icon} />
                                &nbsp;{t('Upload Images')}
                            </button>
                            {isUploading && <div>{t('업로드 중입니다. 잠시만 기다려주세요...')}</div>} {/* 업로드 상태 메시지 */}
                            <div className={styles.imageList}>
                                {imageFiles.map((file, index) => (
                                    <div key={index}>
                                        {file.name} <button onClick={() => removeSelectedFile(index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : selectedOption === 'Select' ? (
                        <div>
                            {t('일괄 입력하실 데이터 카테고리를 선택해주세요.')}
                        </div>
                    ) : selectedOption === 'IC50 Graph data' ? (
                        <>
                            <input type="file" accept=".xlsx, .xls" multiple className={styles.fileInput} onChange={handleFilesUpload} />
                            <button onClick={handleDownload}>
                                <img src={download_icon} />
                                &nbsp;{t('엑셀 폼 다운로드')}
                            </button>
                            <button onClick={handleSubmit}>
                                <img src={upload_icon} />
                                &nbsp;{t('Upload')}
                            </button>
                            {isUploading && <div>{t('업로드 중입니다. 잠시만 기다려주세요...')}</div>} {/* 업로드 상태 메시지 */}
                            <div className={styles.fileList}>
                                {selectedFiles.map((file, index) => (
                                    <div key={index}>
                                        {file.fileName} <button onClick={() => removeSelectedFile(index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <input type="file" accept=".xlsx, .xls" className={styles.fileInput} onChange={handleFileUpload} />
                            {/* <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} /> */}
                            <button onClick={handleDownload}>
                                <img src={download_icon} />
                                &nbsp;{t('엑셀 폼 다운로드')}
                            </button>
                            <button onClick={handleSubmit}>
                                <img src={upload_icon} />
                                &nbsp;{t('Upload')}
                            </button>
                            {isUploading && <div>{t('업로드 중입니다. 잠시만 기다려주세요...')}</div>} {/* 업로드 상태 메시지 */}
                        </>
                    )}
                </div>
                <div>
                    {tmp}
                </div>
            </div>
        </div >
    );
}

export default DBInputModal;
