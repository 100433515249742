import styles from "./BankingMain.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import SearchBox from '../img/icon/SearchBox.png';
import PatientInfoIcon from '../img/icon/PatientInfoIcon.png';
import OrganoidInfoIcon from '../img/icon/OrganoidInfoIcon.png';
import StorageIcon from '../img/icon/StorageIcon.png';
import PlusInCircle from '../img/icon/PlusInCircleIcon.png';
import addNewOrganoid from '../img/icon/AddNewOrganoidIcon.png';
import addNewBanking from '../img/icon/AddNewBankingIcon.png';

//component
import CancerModels from "../components/left_tab/CancerModels";
import BankingRigthTabOrganoid from "./rightTab/BankingRightTab_organoid";
import BankingRigthTabPatient from "./rightTab/BankingRightTab_patient";
import StorageBox from "./rightTab/StorageBox";
import ErrorPage from './rightTab/BankingRightTab_Error';
import PatientList from './leftTab/PatientList';
import BankingHeader from "./BankingHeader";
import DataInput from "./rightTab/DataInput";
import GoogleLogin from "../login/GoogleLogin";
import StorageList from "./leftTab/StorageList";
import DashBoard from "./managementComponent/DashBoard";
import DraftPage from "./managementComponent/DraftPage";
import AddNewOrganoidModal from "../components/modal/AddNewOrganoid";
import AddNewBankingModal from "../components/modal/AddNewBanking";
import NGSMainPage from "./NGSPage/NGSMainPage";

function BankingContent() {
    const { t } = useTranslation();
    // 오가노이드 추가 모달 변수
    const [visibleAddOrganoidModal, setVisibleAddOrganoidModal] = useState(false);
    const handleAddNewOrganoidClick = () => {
        setVisibleAddOrganoidModal(true);
    }
    const closeAddNewOrganoidModal = () => {
        setVisibleAddOrganoidModal(false);
    }

    // 오가노이드 추가 모달 변수
    const [visibleAddBankingModal, setVisibleAddBankingModal] = useState(false);
    const handleAddNewBankingClick = () => {
        setVisibleAddBankingModal(true);
    }
    const closeAddNewBankingModal = () => {
        setVisibleAddBankingModal(false);
    }

    // 새로운 상태 추가: 검색 박스 활성화 여부
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Organoid');
    // 
    // 이미지 클릭시 이벤트 핸들러
    const handleSearchBoxClick = () => {
        setIsSearchActive(true); // 검색 활성화
    };
    const handleRadioChange = (event) => {
        setSelectedTab(event.target.value);
    };
    const handleInputChange = (event) => {
        setSearchOrganoidString(event.target.value);
        // 필요한 경우, 여기에서 검색 실행 등의 추가 로직 구현
    };
    const [searchOranoidString, setSearchOrganoidString] = useState('');
    const inputChange = (event) => {
        setSearchOrganoidString(event.target.value);
    }
    const [searchPatientString, setSearchPatientString] = useState('');
    const inputPatientChange = (event) => {
        setSearchPatientString(event.target.value);
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [authLevel, setAuthLevel] = useState();

    fetch('/api/getAuthLevel', { method: 'POST' })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                setAuthLevel(data.authLevel);
                setIsLoggedIn(true);
            }
        })
        .catch(error => console.log(error));

    const handleLogin = (loggedIn) => {
        setIsLoggedIn(loggedIn);
    };

    return (
        <>
            {isLoggedIn ? (
                <>
                    {authLevel !== null && authLevel >= 1 ? (
                        <>
                            <BankingHeader></BankingHeader>
                            <div className={styles.bankingMain}>
                                <div className={styles.bankingLeft}>
                                    {/* 검색 박스 이미지 또는 입력 필드 렌더링 */}
                                    <div className={styles.SearchBoxDiv} onClick={handleSearchBoxClick}>
                                        {isSearchActive ? (
                                            <input
                                                type="text"
                                                className={styles.organoidSearch}
                                                value={searchOranoidString}
                                                onChange={handleInputChange}
                                                autoFocus // 입력 필드가 활성화되면 자동으로 포커스
                                                onBlur={() => setIsSearchActive(false)}
                                            />
                                        ) : (
                                            <img src={SearchBox} className={styles.SearchBox} />
                                        )}
                                    </div>
                                    <div className={styles.radioTabBox}>
                                        <img src={OrganoidInfoIcon} className={styles.radioTabImg} />
                                        <span className={styles.radioTabText}>Organoid</span>
                                        <input type="radio" value="Organoid" checked={selectedTab === 'Organoid'} onChange={handleRadioChange}></input>
                                    </div>
                                    <div className={styles.radioTabBox}>
                                        <img src={PatientInfoIcon} className={styles.radioTabImg} />
                                        <span className={styles.radioTabText}>Patient Info</span>
                                        <input type="radio" value="PatientInfo" checked={selectedTab === 'PatientInfo'} onChange={handleRadioChange}></input>
                                    </div>
                                    <div className={styles.radioTabBox}>
                                        <img src={StorageIcon} className={styles.radioTabImg} />
                                        <span className={styles.radioTabText}>Storage</span>
                                        <input type="radio" value="Storage" checked={selectedTab === 'Storage'} onChange={handleRadioChange}></input>
                                    </div>
                                    <div className={styles.margin1vh} />
                                    <div className={styles.addDataBox}>
                                        {
                                            visibleAddOrganoidModal && <AddNewOrganoidModal onClose={closeAddNewOrganoidModal} />
                                        }
                                        {
                                            visibleAddBankingModal && <AddNewBankingModal onClose={closeAddNewBankingModal} />
                                        }
                                        <button className={styles.addButton} onClick={handleAddNewOrganoidClick}>
                                            <img src={PlusInCircle} className={styles.plusInCircle}></img>
                                            Add New Organoid Info
                                        </button>
                                    </div>
                                    <div className={styles.addDataBox}>
                                        <button className={styles.addButton} onClick={handleAddNewBankingClick}>
                                            <img src={PlusInCircle} className={styles.plusInCircle}></img>
                                            Add New Organoid Banking
                                        </button>
                                    </div>
                                    <div className={styles.leftSideMenu}>
                                        {/* 조건부 렌더링으로 탭 내용 표시 */}
                                        {selectedTab === 'Organoid' && <CancerModels search={searchOranoidString.replace('#', 'hashmark')} />}
                                        {selectedTab === 'PatientInfo' && <PatientList search={searchOranoidString.replace('#', 'hashmark')} />}
                                        {selectedTab === 'Storage' && <StorageList authLevel={authLevel} />}
                                    </div>
                                </div>
                                <div className={styles.bankingRight}>
                                    <Routes>
                                        {/* <Route path={"/"} element={<MainImage />}></Route> */}
                                        <Route path={"/"} element={<DashBoard />}></Route>
                                        <Route path={"/draftpage"} element={<DraftPage />}></Route>
                                        <Route path={"/NGS"} element={<NGSMainPage />}></Route>
                                        {/* <Route path={"/input"} element={<DataInput />}></Route> */}
                                        <Route path={"/organoid/:tmpKey"} element={<BankingRigthTabOrganoid />}></Route>
                                        <Route path={"/organoid/:tmpKey/:tab"} element={<BankingRigthTabOrganoid />}></Route>
                                        <Route path={"/organoid/:tmpKey/:tab/:timeKey"} element={<BankingRigthTabOrganoid />}></Route>
                                        <Route path={"/patient/:tmpKey"} element={<BankingRigthTabPatient />}></Route>
                                        <Route path={"/:vessel/:line/:box"} element={<StorageBox />}></Route>
                                        <Route path={"/%c0"} element={<ErrorPage />}></Route>
                                        <Route path={"*"} element={<ErrorPage />}></Route>
                                    </Routes>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2>{t('관리자에게 접속 권한을 요청하세요')}</h2>
                        </>
                    )
                    }
                </>
            ) : (
                <>
                    <GoogleLogin onLogin={handleLogin} />
                </>
            )}

        </>
    );
}
function BankingMain() {
    return (
        <BrowserRouter>
            <BankingContent />
        </BrowserRouter>
    );
}

export default BankingMain;