import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './WESContent.module.css';
import axios from 'axios';

function WESContent() {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.get('/api/files?type=WES');
                setFileList(response.data.files || []);
            } catch (error) {
                console.error('Error fetching WES file list:', error);
            }
        };

        fetchFiles();
    }, []);

    const filteredFiles = fileList.filter((file) =>
        file.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleCheckboxChange = (fileName) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.includes(fileName)
                ? prevSelectedFiles.filter((file) => file !== fileName)
                : [...prevSelectedFiles, fileName]
        );
    };

    const handleDownload = async () => {
        if (selectedFiles.length === 0) {
            alert(t('다운로드할 파일을 선택하세요.'));
            return;
        }

        try {
            const response = await axios.post(
                '/api/download',
                { type: 'WES', files: selectedFiles },
                { responseType: 'blob' }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'WES_files.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading WES files:', error);
            alert(t('파일 다운로드 중 오류가 발생했습니다.'));
        }
    };

    return (
        <div className={styles.wesContent}>
            <h2>{t('WES 파일 목록')}</h2>
            <input
                type="text"
                placeholder={t('파일 이름으로 필터링')}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={styles.filterInput}
            />
            <ul className={styles.fileList}>
                {filteredFiles.map((file) => (
                    <li key={file}>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedFiles.includes(file)}
                                onChange={() => handleCheckboxChange(file)}
                            />
                            {file}
                        </label>
                    </li>
                ))}
            </ul>
            <button onClick={handleDownload}>{t('선택한 WES 파일 다운로드')}</button>
        </div>
    );
}

export default WESContent;
