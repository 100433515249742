import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RNAContent.module.css';
import axios from 'axios';

function RNAContent() {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.get('/api/files?type=RNA');
                setFileList(response.data.files || []);
            } catch (error) {
                console.error('Error fetching RNA file list:', error);
            }
        };
        fetchFiles();
    }, []);

    // SSE 연결
    useEffect(() => {
        const eventSource = new EventSource('/api/progress');

        eventSource.onmessage = (event) => {
            const progress = parseInt(event.data, 10);
            console.log(`[INFO] Progress update: ${progress}%`);
            setDownloadProgress(progress);
        };

        eventSource.onerror = () => {
            console.error('Progress SSE connection lost.');
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, []);

    const handleCheckboxChange = (fileName) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.includes(fileName)
                ? prevSelectedFiles.filter((file) => file !== fileName)
                : [...prevSelectedFiles, fileName]
        );
    };

    const handleDownload = async () => {
        if (selectedFiles.length === 0) {
            alert(t('다운로드할 파일을 선택하세요.'));
            return;
        }

        setIsDownloading(true);
        setDownloadProgress(0);

        try {
            const response = await axios.post(
                '/api/download',
                { type: 'RNA', files: selectedFiles },
                {
                    responseType: 'blob',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            if (!response.data || response.data.size === 0) {
                throw new Error('압축된 파일이 비어 있습니다.');
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'RNA_files.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading RNA files:', error);
            alert(t(`파일 다운로드 중 오류가 발생했습니다: ${error.message}`));
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className={styles.rnaContent}>
            <h2>{t('RNA 파일 목록')}</h2>
            <input
                type="text"
                placeholder={t('파일 이름으로 필터링')}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={styles.filterInput}
            />
            <ul className={styles.fileList}>
                {fileList
                    .filter((file) => file.toLowerCase().includes(filterText.toLowerCase()))
                    .map((file) => (
                        <li key={file}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedFiles.includes(file)}
                                    onChange={() => handleCheckboxChange(file)}
                                />
                                {file}
                            </label>
                        </li>
                    ))}
            </ul>
            <button
                onClick={handleDownload}
                disabled={isDownloading}
                className={isDownloading ? styles.disabledButton : ''}
            >
                {isDownloading ? t('다운로드 중...') : t('선택한 RNA 파일 다운로드')}
            </button>

            {isDownloading && (
                <>
                    <div className={styles.overlay}></div>
                    <div className={styles.popup}>
                        <h3>{t('다운로드 진행 중')}</h3>
                        <p>
                            {t('진행률')}: {downloadProgress || 0}%
                        </p>
                        <div className={styles.progressBar}>
                            <div
                                className={styles.progress}
                                style={{ width: `${downloadProgress || 0}%` }}
                            ></div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default RNAContent;
