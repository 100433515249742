import React, { useEffect, useState, useRef } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Scatter } from 'recharts';
import styles from './DrugResponsePlot_Fixed.module.css';
import numeric from 'numeric';
import { calculateIC50 } from './IC50_cal';

// const { spawn } = require('child_process');

// IC50 계산 함수 (기존 로직과 별개로 추가)
export const performOptimization = (xdata, ydata) => {

  return calculateIC50(xdata, ydata).ic50; // IC50 값만 반환
};

// 행렬 연산 헬퍼 함수들 (기존 함수)
function matrixMultiply(a, b) {
  const result = new Array(a.length).fill().map(() => new Array(b[0].length).fill(0));
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b[0].length; j++) {
      for (let k = 0; k < b.length; k++) {
        result[i][j] += a[i][k] * b[k][j];
      }
    }
  }
  return result;
}

function transpose(matrix) {
  return matrix[0].map((_, i) => matrix.map(row => row[i]));
}

function solveSystem(A, b) {
  const n = A.length;
  const augmented = A.map((row, i) => [...row, b[i]]);
  for (let i = 0; i < n; i++) {
    let maxRow = i;
    for (let j = i + 1; j < n; j++) {
      if (Math.abs(augmented[j][i]) > Math.abs(augmented[maxRow][i])) {
        maxRow = j;
      }
    }
    [augmented[i], augmented[maxRow]] = [augmented[maxRow], augmented[i]];
    for (let j = i + 1; j < n; j++) {
      const factor = augmented[j][i] / augmented[i][i];
      for (let k = i; k <= n; k++) {
        augmented[j][k] -= factor * augmented[i][k];
      }
    }
  }
  const x = new Array(n).fill(0);
  for (let i = n - 1; i >= 0; i--) {
    x[i] = augmented[i][n];
    for (let j = i + 1; j < n; j++) {
      x[i] -= augmented[i][j] * x[j];
    }
    x[i] /= augmented[i][i];
  }
  return x;
}

function computeJacobian(x, params, model) {
  const h = 1e-8;
  const n = x.length;
  const p = params.length;
  const J = new Array(n).fill().map(() => new Array(p).fill(0));
  for (let j = 0; j < p; j++) {
    const paramsPlusH = [...params];
    paramsPlusH[j] += h;
    const paramsMinusH = [...params];
    paramsMinusH[j] -= h;
    for (let i = 0; i < n; i++) {
      J[i][j] = (model(x[i], ...paramsPlusH) - model(x[i], ...paramsMinusH)) / (2 * h);
    }
  }
  return J;
}

function levenbergMarquardt(x, y, model, initialParams, maxIterations = 1000) {
  let params = [...initialParams];
  let iteration = 0;
  let prevChiSquare = Infinity;
  let damping = 0.1;
  while (iteration < maxIterations) {
    const predicted = x.map(xi => model(xi, ...params));
    const residuals = y.map((yi, i) => yi - predicted[i]);
    const chiSquare = residuals.reduce((sum, r) => sum + r * r, 0);
    if (Math.abs(chiSquare - prevChiSquare) < 1e-8 && iteration > 100) {
      break;
    }
    const J = computeJacobian(x, params, model);
    const JT = transpose(J);
    const JTJ = matrixMultiply(JT, J.map(row => [row]).map(col => col[0]));
    const JTr = matrixMultiply(JT, residuals.map(r => [r])).map(r => r[0]);
    const dampedJTJ = JTJ.map((row, i) =>
      row.map((val, j) => val + (i === j ? damping * (1 + Math.abs(val)) : 0))
    );
    try {
      const delta = solveSystem(dampedJTJ, JTr);
      const newParams = params.map((p, i) => p + delta[i]);
      const newPredicted = x.map(xi => model(xi, ...newParams));
      const newResiduals = y.map((yi, i) => yi - newPredicted[i]);
      const newChiSquare = newResiduals.reduce((sum, r) => sum + r * r, 0);
      if (newChiSquare < chiSquare) {
        params = newParams;
        damping = Math.max(damping * 0.1, 1e-7);
        prevChiSquare = chiSquare;
      } else {
        damping = Math.min(damping * 10, 1e7);
      }
    } catch (e) {
      damping = Math.min(damping * 10, 1e7);
    }
    iteration++;
  }
  return params;
}

const colors = ["#8884D8", "#82CA9D", "#FFBB28", "#FF8042", "#8DD1E1", "#D0ED57", "#A4DE6C", "#D0D57F"];

const DoseResponseCurve = ({ data }) => {
  const chartContainerRef = useRef(null);
  const [chartSize, setChartSize] = useState({ width: 600, height: 300 });

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        const { clientWidth, clientHeight } = chartContainerRef.current;
        setChartSize({
          width: clientWidth,
          height: clientHeight,
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [fittedParams, setFittedParams] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [yAxisMax, setYAxisMax] = useState(150);
  const [xAxisDomain, setXAxisDomain] = useState([0, 1]);
  const [ic50Values, setIC50Values] = useState({});

  useEffect(() => {
    const fetchIC50Data = async () => {
      const result = [];
      const fittedParamsList = {};
      const ic50List = {}; // IC50과 logIC50을 하나의 문자열로 저장
      let globalXMin = Infinity;
      let globalXMax = -Infinity;
      let globalYMax = 0;

      // 모든 fetch 요청을 병렬로 처리하고, 모든 응답이 완료된 후 실행
      const fetchRequests = Object.keys(data).map(async (key) => {
        const xdata = data[key].xdata;
        const ydata = data[key].ydata.map(y => Math.max(y, 0));

        try {
          let reqOption = {
            method: "post",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify({
              xdata: xdata,
              ydata: ydata,
            }),
          };

          const response = await fetch("/api/calculateIC50", reqOption);
          const responseData = await response.json();
          if (responseData) {
            // 응답 데이터를 하나의 문자열로 결합하여 저장
            ic50List[key] = `logIC50: ${responseData.logIC50}, IC50: ${responseData.IC50}`;
          }

          // 데이터 그래프 처리
          const model = function (x, top, bottom, logIC50) {
            const hillSlope = -1;
            return bottom + (top - bottom) / (1 + Math.pow(10, hillSlope * (x - logIC50)));
          };
          const initialParams = [0, 100, 1];
          const fitted = levenbergMarquardt(xdata, ydata, model, initialParams);

          fittedParamsList[key] = fitted;

          xdata.forEach((x, i) => {
            const fittedValue = Math.max(model(x, ...fitted), 0);
            result.push({
              x,
              experimental: ydata[i],
              fitted: fittedValue,
              group: key
            });
            globalYMax = Math.max(globalYMax, ydata[i], fittedValue);
          });

          const xmin = Math.min(...xdata);
          const xmax = Math.max(...xdata);
          globalXMin = Math.min(globalXMin, xmin);
          globalXMax = Math.max(globalXMax, xmax);

          const step = (xmax - xmin) / 1000;
          for (let x = xmin; x <= xmax; x += step) {
            const fittedValue = Math.max(model(x, ...fitted), 0);
            result.push({
              x,
              fitted: fittedValue,
              group: key
            });
            globalYMax = Math.max(globalYMax, fittedValue);
          }
        } catch (error) {
          console.error(`Error fetching IC50 for key ${key}:`, error);
        }
      });

      // 모든 fetch가 완료되었을 때 실행
      await Promise.all(fetchRequests);

      result.sort((a, b) => a.x - b.x);
      setChartData(result);
      setFittedParams(fittedParamsList);
      setIC50Values(ic50List); // 여기에서 결합된 문자열을 사용할 수 있음
      setXAxisDomain([0, globalXMax + 1]);
      setYAxisMax(globalYMax > 150 ? 200 : 150);
    };

    fetchIC50Data();
  }, [data]);

  // 기존 범례에 IC50 값을 append하여 표시
  const generateLegendWithIC50 = () => {
    return Object.keys(ic50Values).map((key, index) => {
      const ic50 = ic50Values[key];
      return (
        <React.Fragment key={key}>
          <Scatter
            name={`Experimental Data - ${key}`}
            dataKey="experimental"
            data={chartData.filter(item => item.group === key)}
            fill={colors[index % colors.length]}
          />
          <Line
            name={`${key} (${ic50})`}
            type="monotone"
            dataKey="fitted"
            data={chartData.filter(item => item.group === key)}
            stroke={colors[index % colors.length]}
            dot={false}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <div ref={chartContainerRef} className={styles.chartContainer}>
      <h2 className="text-xl font-bold mb-4">Drug Dose Response Curve</h2>
      <LineChart
        width={chartSize.width * 0.9}
        height={chartSize.height * 0.8}
        data={chartData}
        margin={{ top: 20, right: 140, left: 60, bottom: 80 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          domain={xAxisDomain}
          type="number"
          tickCount={Math.ceil(xAxisDomain[1]) + 1}
          label={{
            value: 'Log(Inhibitor Concentration)',
            position: 'bottom',
            offset: -10,
          }}
          tickFormatter={(tick) => tick.toFixed(0)}
        />
        <YAxis
          domain={[0, yAxisMax]}
          ticks={[0, 50, 100, 150, yAxisMax]}
          label={{
            value: 'Normalized Response',
            angle: -90,
            position: 'insideLeft',
            dx: -20,
            dy: 70,
          }}
          tickFormatter={(tick) => tick.toFixed(0)}
        />
        <Tooltip />
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{
            paddingLeft: 20,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        {generateLegendWithIC50()}
      </LineChart>
    </div>
  );
};

export default DoseResponseCurve;
