import React, { useState } from 'react';
import styles from "./NGSMainPage.module.css";
import { useTranslation } from 'react-i18next';

//--------------------------------- 이미지 파일 임포트
import smallDNABlack2 from '../../img/icon/smallDNABlack2.png'

function NGSMainPage() {
    const { t } = useTranslation();
    //--------------------------------- 변수
    const [selectedNGSType, setSelectedNGSType] = useState('RNA');  // RNA 기본 선택

    // 선택한 NGS 타입에 따라 다르게 보여줄 콘텐츠
    const renderNGSContent = () => {
        if (selectedNGSType === 'RNA') {
            return <div>{t('RNA 관련 내용이 여기에 표시됩니다.')}</div>;
        } else if (selectedNGSType === 'WES') {
            return <div>{t('WES 관련 내용이 여기에 표시됩니다.')}</div>;
        }
    };

    return (
        <div className={styles.rightTab}>
            <div className={styles.topMenu}>
                <div className={styles.curOrganoid}>
                    <img src={smallDNABlack2} className={styles.draftListIcon} />
                    &nbsp;{t('오가노이드 종류')}
                </div>
            </div>
            <div className={styles.rightTabBottomBox}>
                <div className={styles.selectNGSType}>
                    <label>
                        <input
                            type="radio"
                            value="RNA"
                            checked={selectedNGSType === 'RNA'}
                            onChange={() => setSelectedNGSType('RNA')}
                        />
                        {t('RNA')}
                    </label>
                    &nbsp;&nbsp;
                    <label>
                        <input
                            type="radio"
                            value="WES"
                            checked={selectedNGSType === 'WES'}
                            onChange={() => setSelectedNGSType('WES')}
                        />
                        {t('WES')}
                    </label>
                </div>

                {/* 선택된 NGS 타입에 따른 내용 렌더링 */}
                <div className={styles.ngsContent}>
                    {renderNGSContent()}
                </div>
            </div>
        </div>
    );
}

export default NGSMainPage;
