import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const UploadExcel = () => {
  const { t } = useTranslation();
  const [fileData, setFileData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Organoid summary');
  const [alertMsg, setAlertMsg] = useState({ message: '', type: 'danger' });
  const [imageFiles, setImageFiles] = useState([]);
  const [tmp, setTmp] = useState('');
  const [isUploading, setIsUploading] = useState(false); // 업로드 상태 추가


  //url 매핑
  const apiUrlMap = {
    'Organoid summary': '/api/setOrganoidInfo',
    'Patient summary': '/api/setPatientInfo',
    '비공개용 Patient info(stomach)': '/api/setPrivatePatientInfo',
    'Pathology_Picture_picture_data': '/api/setPathologyPicturePictureData',
    'Light_Microscope_picture_data': '/api/setLightMicroscopePictureData',
    'Banking master stock': '/api/setBankingInfo',
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate() + 1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const handleImageUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    setImageFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeSelectedFile = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleImageSubmit = async () => {
    setIsUploading(true); // 업로드 시작
    try {
      const apiUrl = apiUrlMap[selectedOption];
      const formData = new FormData();
      console.log(apiUrl);

      for (let i = 0; i < imageFiles.length; i++) {
        formData.append('images', imageFiles[i]);
      }
      const res = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.data !== '') {
        const alertType = res.data === 'Success' ? 'success' : 'danger';
        setAlertMsg({ message: res.data, type: alertType });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const processMergedCells = (worksheet) => {
    const mergedCells = worksheet['!merges'] || [];
    mergedCells.forEach((range) => {
      const s = range.s; // 시작 셀
      const e = range.e; // 끝 셀
      const mainCellValue = worksheet[XLSX.utils.encode_cell(s)];

      for (let R = s.r; R <= e.r; ++R) {
        for (let C = s.c; C <= e.c; ++C) {
          if (R !== s.r || C !== s.c) {
            const cell = {
              ...mainCellValue,
              v: mainCellValue.v,
              t: mainCellValue.t,
            };
            worksheet[XLSX.utils.encode_cell({ r: R, c: C })] = cell;
          }
        }
      }
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();




    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary', cellDates: true }); // cellDates: true 옵션 추가
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      processMergedCells(worksheet);
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '', raw: true });
      data.forEach((row) => {
        row.forEach((cell, index) => {
          if (cell instanceof Date) {
            row[index] = formatDate(cell);
          }
        });
      });
      setFileData(data);
    };


    reader.readAsBinaryString(file);
  };

  const handleSubmit = async () => {
    try {
      const apiUrl = apiUrlMap[selectedOption];
      if (apiUrl === '/api/setPrivatePatientInfo') {
        // console.log(fileData);
        setTmp(fileData[1][10]);
      }
      const res = await axios.post(apiUrl, { data: fileData });

      if (res.data !== '') {
        const alertType = res.data === 'Success' ? 'success' : 'danger';
        setAlertMsg({ message: res.data, type: alertType });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDownload = () => {
    const fileMap = {
      'Organoid summary': 'OrganoidSummary.xlsx',
      'Patient summary': 'PatientSummary.xlsx',
      'Banking master stock': 'banking_form.xlsx',
    };

    const fileName = fileMap[selectedOption];

    if (fileName) {
      const link = document.createElement('a');
      link.href = process.env.PUBLIC_URL + '/files/' + fileName;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      alert('엑셀 파일 양식을 지원하지 않습니다.');
    }
  };

  return (
    <div>
      {alertMsg.message && (
        <Alert variant={alertMsg.type} onClose={() => setAlertMsg({ message: "", type: "danger" })} dismissible>
          <pre>{alertMsg.message}</pre>
        </Alert>
      )}
      <button onClick={handleDownload}>{t('엑셀 폼 다운로드')}</button>
      <div>
        <select value={selectedOption} onChange={handleSelectChange}>
          <option>Organoid summary</option>
          <option>Patient summary</option>
          <option>비공개용 Patient info(stomach)</option>
          <option>Pathology_Picture_picture_data</option>
          <option>Light_Microscope_picture_data</option>
          <option>Banking master stock</option>
        </select>
        {selectedOption === 'Pathology_Picture_picture_data' || selectedOption === 'Light_Microscope_picture_data' ? (
          <>
            <input type="file" accept="image/*" multiple onChange={handleImageUpload} />
            <button onClick={handleImageSubmit}>Upload Images</button>
            {isUploading && <div>{t('업로드 중입니다. 잠시만 기다려주세요...')}</div>} {/* 업로드 상태 메시지 */}
            <div>
              {imageFiles.map((file, index) => (
                <div key={index}>
                  {file.name} <button onClick={() => removeSelectedFile(index)}>x</button>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
            <button onClick={handleSubmit}>Upload</button>
            {isUploading && <div>{t('업로드 중입니다. 잠시만 기다려주세요...')}</div>} {/* 업로드 상태 메시지 */}
          </>
        )}
      </div>
      <div>
        {tmp}
      </div>
    </div>
  );
};

export default UploadExcel;