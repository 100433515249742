import React from 'react';
import Plot from 'react-plotly.js';
import { calculateIC50 } from './IC50_cal';
import numeric from 'numeric';
import * as d3 from 'd3-scale-chromatic';
import styles from './ErrorBarGraph.module.css';

const generateColors = (numColors) => {
  return d3.schemeCategory10.concat(d3.schemeSet1, d3.schemeSet2, d3.schemeSet3).slice(0, numColors);
};

const ErrorBarGraph = ({ data }) => {
  const colors = generateColors(100);

  const traces = Object.keys(data).map((chemical, index) => {
    const xdata = data[chemical].xdata;
    const ydata = data[chemical].ydata;

    const result = calculateIC50(xdata, ydata);
    const solution = result.solution;

    // 모델 데이터 생성
    const xModel = numeric.linspace(-3, 10, 100);  // 더 많은 점을 사용해 부드러운 곡선 생성
    const yModel = xModel.map(x => result.model(x, solution[0], solution[1]));

    // 실제 데이터 포인트에 대해 에러바와 S자형 곡선 생성
    const trace = {
      x: xModel,
      y: yModel,
      type: 'scatter',
      mode: 'lines',  // 라인만 표시
      name: `${chemical} IC50: ${solution[0].toFixed(2)}`,
      line: { shape: 'spline', color: colors[index % colors.length] }  // S자형 곡선을 위한 설정
    };

    const errorBars = {
      x: xdata,
      y: ydata,
      type: 'scatter',
      mode: 'markers',
      // name 속성 제거 또는 범례 비활성화
      showlegend: false,
      error_y: {
        type: 'data',
        array: ydata.map(y => y * 0.1),  // 예시로 표준 편차를 10%로 설정
        visible: true
      },
      marker: {
        color: colors[index % colors.length],
        size: 8
      }
    };

    return [trace, errorBars];
  }).flat();  // 모든 데이터를 하나의 배열로 평탄화

  return (
    <div className={styles.drugResponsePageMain}>
      <Plot
        data={traces}
        layout={{
          title: 'Drug Response Line Plot with Smoothing and Error Bars',
          width: 900,  // 그래프 너비 조정
          height: 600, // 그래프 높이 조정
          xaxis: {
            title: 'Concentration log10(nM)',
            range: [-3, 10]
          },
          yaxis: {
            title: 'Cell Viability (%)',
            range: [0, 160]
          },
          showlegend: true,
          legend: {
            x: 1,
            xanchor: 'left',
            y: 1
          }
        }}
        config={{ responsive: true }}
      />
    </div>
  );
};

export default ErrorBarGraph;
