import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance'; // Axios 인스턴스 가져오기
import styles from './MultiLargeFileUploads.module.css';

const MultiLargeFileUploads = ({ onClose }) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadType, setUploadType] = useState('WES'); // Default upload type

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleUploadTypeChange = (event) => {
        setUploadType(event.target.value);
    };

    const handleFileUpload = async () => {
        if (files.length === 0) {
            alert(t('Please select files to upload.'));
            return;
        }

        setUploading(true);
        const formData = new FormData();

        // Append files to FormData
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        // Append upload type
        formData.append('uploadType', uploadType);

        try {
            const response = await axiosInstance.post('/multiLargeFileUploads', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    setUploadProgress(percentCompleted);
                },
            });

            // Success: Back-end accepted the request
            console.log('Response from server:', response.data);
            setUploadProgress(100); // Ensure progress is set to 100% on completion
            alert(t('Files uploaded successfully.')); // Success alert
        } catch (error) {
            // Error handling
            console.error('Error uploading files:', error.response?.data || error.message);
            alert(t('Failed to upload files.'));
        } finally {
            // Reset UI state
            setUploading(false);
            setFiles([]);
            setUploadProgress(0);
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <span>{t('Multi Large File Uploads')}</span>
                    <button className={styles.closeButton} onClick={onClose}>X</button>
                </div>
                <div className={styles.radioGroup}>
                    <label>
                        <input
                            type="radio"
                            name="uploadType"
                            value="WES"
                            checked={uploadType === 'WES'}
                            onChange={handleUploadTypeChange}
                        />
                        {t('WES')}
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="uploadType"
                            value="RNA"
                            checked={uploadType === 'RNA'}
                            onChange={handleUploadTypeChange}
                        />
                        {t('RNA')}
                    </label>
                </div>
                <div className={styles.textInput}>
                    <input type="file" multiple onChange={handleFileChange} />
                </div>
                {files.length > 0 && (
                    <div className={styles.uploadDetails}>
                        {[...files].map((file, index) => (
                            <p key={index}>{file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
                        ))}
                    </div>
                )}
                <div className={styles.draftButtonBox}>
                    <button
                        className={styles.submitButton}
                        onClick={handleFileUpload}
                        disabled={uploading}
                    >
                        {uploading
                            ? `${t('Uploading')}... (${uploadProgress}%)`
                            : t('Upload')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MultiLargeFileUploads;
