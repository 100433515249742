import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Link, useLocation } from 'react-router-dom';  // 추가
import Styles from './BankingHeader.module.css';
import BankingList_Print from './detail/BankingList_Print';
import { useTranslation } from 'react-i18next'; // 추가
import Test from './test';//색상테스트
import Select from 'react-select';
import BankingHeader_modal from './BankingHeader_modal';

import Headername from '../img/gradiantbioconvergence/header-name.png';
import HomeIconOrange from '../img/common/home_icon_orange.png'
import HomeIconGrey from '../img/common/home_icon_grey.png'
import DraftIconGrey from '../img/common/draftMenuIcon.png'
import DraftIconOrange from '../img/common/draftMenuIconOrange.png'
import AmericaFlag from '../img/nationalFlag/AmericaNationalFlag.jpg';
import KoreaFlag from '../img/nationalFlag/KoreaNationalFlag.jpg';
import PersonIcon from '../img/common/personIcon.png';
import DNAIcon_gray from '../img/icon/DNAIcon_gray_small.png';
import DNAIcon_orange from '../img/icon/DNAIcon_orange_small.png';

function BankingHeader(props) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('dashboard');
  const [printModalIsOpen, setPrintModalIsOpen] = useState(false);
  const { i18n } = useTranslation(); // 추가
  const { t } = useTranslation();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  useEffect(() => {
    // 경로에 따라 선택된 탭을 변경합니다
    if (location.pathname === '/') {
      setSelectedTab('dashboard');
    } else if (location.pathname === '/draftpage') {
      setSelectedTab('drafts');
    } else if (location.pathname === '/NGS') {
      setSelectedTab('NGS');
    } else {
      setSelectedTab('');
    }
  }, [location]);

  // 탭 선택 핸들러
  const selectTab = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleCancel = () => {
    setPrintModalIsOpen(false);
  };

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value); // 추가
  };

  // 드롭다운 옵션 정의
  const languageOptions = [
    // { value: 'en', label: (<div><img src={AmericaFlag} alt="USA" style={{ height: '15px', width: '20px', marginRight: '10px' }} />English</div>) },
    { value: 'en', label: (<div><img src={AmericaFlag} alt="USA" style={{ width: '20%', marginRight: '10px' }} />English</div>) },
    { value: 'ko', label: (<div><img src={KoreaFlag} alt="Korea" style={{ width: '20%', marginRight: '10px' }} />한국어</div>) },
  ];

  // 언어 변경 핸들러
  const handleLanguageChange = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
  };

  const displayKeys = [
    'Key',
    'LabCode',
    'PatientCode',
    'SampleType',
    'Organism',
    'Type',
    'Subtype',
    'Origin',
    'Pair',
    'WES',
    'RNASeq',
    'MutationInformation',
    'cancer_level',
  ];

  return (
    <div className={Styles.headerFrame}>
      <span className={Styles.headerNameSpan}>
        <Link to="/">
          <img src={Headername} className={Styles.headername} />
        </Link>
      </span>
      <span className={Styles.rightHeader}>
        <span
          className={selectedTab === 'dashboard' ? Styles.selectedTab : Styles.dashBoardSpan}
          onClick={() => selectTab('dashboard')}
        >
          <Link to="/" className={Styles.linkNoStyle}>
            <img
              src={selectedTab === 'dashboard' ? HomeIconOrange : HomeIconGrey}
              className={Styles.homeIcon}
            />
            {t('DASHBOARD')}
          </Link>
        </span>
        <span
          className={selectedTab === 'drafts' ? Styles.selectedTab : Styles.dashBoardSpan}
          onClick={() => selectTab('drafts')}
        >
          <Link to="/draftpage" className={Styles.linkNoStyle}>
            <img
              src={selectedTab === 'drafts' ? DraftIconOrange : DraftIconGrey}
              className={Styles.homeIcon}
            />
            {t('LIST OF DRAFTS')}
          </Link>
        </span>
        {/* <span className={Styles.testBox} > */}
        <span
          className={selectedTab === 'NGS' ? Styles.selectedTab : Styles.dashBoardSpan}
          onClick={() => selectTab('NGS')}
        >
          <Link to="/NGS" className={Styles.linkNoStyle}>
            <img
              src={selectedTab === 'NGS' ? DNAIcon_orange : DNAIcon_gray}
              className={Styles.homeIcon}
            />
            {t('NGS ANALISYS')}
          </Link>
        </span>
        <span className={Styles.testBox}></span>
        <span className={Styles.testBox}></span>
        <span className={Styles.languageSpan}>
          <Select
            defaultValue={languageOptions.find(option => option.value === i18n.language)}
            onChange={handleLanguageChange}
            options={languageOptions}
            className="language-selector"
            classNamePrefix="select"
          />
        </span>
        <span className={Styles.downloadBarcode}>
          <button onClick={() => setPrintModalIsOpen(true)}>{t('바코드 다운로드')}</button>
          <Modal isOpen={printModalIsOpen} style={customStyles}>
            <BankingList_Print />
            {/* <Test onClose={() => setPrintModalIsOpen(false)} /> */}
            <button onClick={handleCancel}>{t('Cancel')}</button>
          </Modal>
        </span>
        <span className={Styles.personSpan}>
          <img src={PersonIcon} className={Styles.personIcon} onClick={() => setIsUserModalOpen(!isUserModalOpen)} />
          {isUserModalOpen && <BankingHeader_modal onClose={() => setIsUserModalOpen(false)} />}
        </span>
      </span>
    </div >
  );
}

const customStyles = {
  content: {
    width: '500px',
    margin: 'auto',
  },
};

export default BankingHeader;
