import React, { useEffect, useState } from "react";
import "./CancerModels.css";
import PDO from "./PDO";
import PDXO from "./PDXO";
import EtcModels from "./EtcModels";


function CancerModels(props) {

    const [numPDOModels, setNum] = useState([{
        num: ''
    }]);
    const [numPDXOModels, setNumX] = useState([{
        num: ''
    }]);
    const [numEctModels, setEtcNum] = useState([{
        num: ''
    }]);
    let str = '';
    if (props.search !== '') {
        if (props.search.includes('hashmark')) {
            str = "AND `Key` LIKE '%" + props.search + "%'";
        } else {
            str = "AND REPLACE(`Key`, 'hashmark', '') LIKE '%" + props.search + "%'";
        }
    }
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: str,
            })
        }

        fetch("/api/CountPDOModels", reqOption)
            .then((response) => response.json())
            .then(data => {
                setNum(data);
            });
    }, [props.search]);

    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: str,
            })
        }

        fetch("/api/CountPDXOModels", reqOption)
            .then((response) => response.json())
            .then(data => {
                setNumX(data);
            });
    }, [props.search]);

    // useEffect(() => {
    //     let reqOption = {
    //         method: "post",
    //         headers: {
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             param: str,
    //         })
    //     }

    //     fetch("/api/CountEtcModels", reqOption)
    //         .then((response) => response.json())
    //         .then(data => {
    //             setEtcNum(data);
    //         });
    // }, [props.search]);
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                // param: props.search.replace('hashmark', ''), // "hashmark" 제외한 검색 조건
                param: props.search, // "hashmark" 제외한 검색 조건
            })
        };

        // Etc 모델 카운트 가져오기
        fetch("/api/CountEtcModels", reqOption)
            .then((response) => response.json())
            .then(data => {
                setEtcNum(data);
            });
    }, [props.search]);


    return (
        <div className="cancerModels">
            {
                numPDOModels.map((num, index) => (
                    <PDO num={num} search={props.search} key={index}></PDO>
                ))
            }
            {
                numPDXOModels.map((num, index) => (
                    <PDXO num={num} search={props.search} key={index}></PDXO>
                ))
            }
            {
                numEctModels.map((num, index) => (
                    <EtcModels num={num} search={props.search} key={index} />
                ))
            }
        </div >
    );
}

export default CancerModels;