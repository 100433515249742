import React, { useState, useEffect } from 'react';
import parseText from './Parse';  // parseText 함수 임포트
import ResponsePlot from './ResponsePlot';
import styles from './DrugResponsePage.module.css';
import { useParams } from 'react-router-dom';
import ErrorBarGraph from './ErrorBarGraph';
import DrugResponsePlot_Fixed from './DrugResponsePlot_Fixed';

const DrugResponsePage = () => {
  const [chemicalData, setChemicalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { tmpKey } = useParams();

  const handleFileRead = (text) => {
    const { processedData } = parseText(text);
    setChemicalData(processedData);
    setLoading(false);
    // console.log(text);
    // console.log(processedData);
  };

  useEffect(() => {
    // tmpKey 값이 변경될 때마다 로딩 상태를 초기화하고 데이터를 다시 불러옴
    setLoading(true);
    setChemicalData(null);

    let reqOption = {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        param: tmpKey
      }),
    };

    fetch("/api/getChemicalData", reqOption)
      .then((response) => response.json())
      .then(data => {
        if (data && data.length > 0 && data[0]?.responsValue) {
          handleFileRead(data[0].responsValue);
        } else {
          setLoading(false);  // 데이터가 없을 때도 로딩 상태를 false로 변경
        }
      })
      .catch(() => setLoading(false));  // 오류 발생 시에도 로딩 상태를 false로 변경
  }, [tmpKey]);  // tmpKey 값이 변경될 때마다 useEffect 실행

  if (loading) {
    return <div>Loading...</div>;  // 데이터를 불러오는 중일 때
  }

  if (!chemicalData) {
    return <div>No data available.</div>;  // 데이터가 없을 때
  }

  return (
    <div className={styles.drugResponsePageMain}>
      {/* <ResponsePlot data={chemicalData} /> */}
      {/* <ErrorBarGraph data={chemicalData} /> */}
      <DrugResponsePlot_Fixed data={chemicalData} />
    </div>
  );
};

export default DrugResponsePage;
