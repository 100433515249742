function matrixMultiply(a, b) {
  const result = new Array(a.length).fill().map(() => new Array(b[0].length).fill(0));
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b[0].length; j++) {
      for (let k = 0; k < b.length; k++) {
        result[i][j] += a[i][k] * b[k][j];
      }
    }
  }
  return result;
}

function transpose(matrix) {
  return matrix[0].map((_, i) => matrix.map(row => row[i]));
}

function solveSystem(A, b) {
  const n = A.length;
  const augmented = A.map((row, i) => [...row, b[i]]);
  for (let i = 0; i < n; i++) {
    let maxRow = i;
    for (let j = i + 1; j < n; j++) {
      if (Math.abs(augmented[j][i]) > Math.abs(augmented[maxRow][i])) {
        maxRow = j;
      }
    }
    [augmented[i], augmented[maxRow]] = [augmented[maxRow], augmented[i]];
    for (let j = i + 1; j < n; j++) {
      const factor = augmented[j][i] / augmented[i][i];
      for (let k = i; k <= n; k++) {
        augmented[j][k] -= factor * augmented[i][k];
      }
    }
  }
  const x = new Array(n).fill(0);
  for (let i = n - 1; i >= 0; i--) {
    x[i] = augmented[i][n];
    for (let j = i + 1; j < n; j++) {
      x[i] -= augmented[i][j] * x[j];
    }
    x[i] /= augmented[i][i];
  }
  return x;
}

function computeJacobian(x, params, model) {
  const h = 1e-8;
  const n = x.length;
  const p = params.length;
  const J = new Array(n).fill().map(() => new Array(p).fill(0));
  for (let j = 0; j < p; j++) {
    const paramsPlusH = [...params];
    paramsPlusH[j] += h;
    const paramsMinusH = [...params];
    paramsMinusH[j] -= h;
    for (let i = 0; i < n; i++) {
      J[i][j] = (model(x[i], ...paramsPlusH) - model(x[i], ...paramsMinusH)) / (2 * h);
    }
  }
  return J;
}

function levenbergMarquardt(x, y, model, initialParams, maxIterations = 1000) {
  let params = [...initialParams];
  let iteration = 0;
  let prevChiSquare = Infinity;
  let damping = 0.1;
  while (iteration < maxIterations) {
    const predicted = x.map(xi => model(xi, ...params));
    const residuals = y.map((yi, i) => yi - predicted[i]);
    const chiSquare = residuals.reduce((sum, r) => sum + r * r, 0);
    if (Math.abs(chiSquare - prevChiSquare) < 1e-8 && iteration > 100) {
      break;
    }
    const J = computeJacobian(x, params, model);
    const JT = transpose(J);
    const JTJ = matrixMultiply(JT, J.map(row => [row]).map(col => col[0]));
    const JTr = matrixMultiply(JT, residuals.map(r => [r])).map(r => r[0]);
    const dampedJTJ = JTJ.map((row, i) =>
      row.map((val, j) => val + (i === j ? damping * (1 + Math.abs(val)) : 0))
    );
    try {
      const delta = solveSystem(dampedJTJ, JTr);
      const newParams = params.map((p, i) => p + delta[i]);
      const newPredicted = x.map(xi => model(xi, ...newParams));
      const newResiduals = y.map((yi, i) => yi - newPredicted[i]);
      const newChiSquare = newResiduals.reduce((sum, r) => sum + r * r, 0);
      if (newChiSquare < chiSquare) {
        params = newParams;
        damping = Math.max(damping * 0.1, 1e-7);
        prevChiSquare = chiSquare;
      } else {
        damping = Math.min(damping * 10, 1e7);
      }
    } catch (e) {
      damping = Math.min(damping * 10, 1e7);
    }
    iteration++;
  }
  return params;
}

export function calculateIC50(xdata, ydata) {
  const model = (x, ic50, hillSlope) => {
    return 100 / (1 + Math.pow(10, (ic50 - x) * hillSlope));
  };

  const initialParams = [1, -1];
  const [logIC50, hillSlope] = levenbergMarquardt(xdata, ydata, model, initialParams);
  const ic50 = Math.pow(10, logIC50);

  return { logIC50: logIC50.toFixed(4), ic50: ic50.toFixed(4), hillSlope: hillSlope.toFixed(4) };
}

// Example usage
const xdata = [0.505, 1.204, 1.903, 2.602, 3.301, 4, 0.505, 1.204, 1.903, 2.602, 3.301, 4, 0.505, 1.204, 1.903, 2.602, 3.301, 4];
const ydata = [91.59, 85.09, 80.54, 58.41, 67.08, 58.06, 87.27, 98.61, 87.89, 83.73, 77.14, 75.99, 96.96, 98.99, 101.2, 80.74, 76.73, 58.39];
// console.log(calculateIC50(xdata, ydata));
