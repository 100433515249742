// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WESContent_wesContent__t3O9- {
    padding: 20px;
}

.WESContent_filterInput__waPUf {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.WESContent_fileList__nSSwv {
    list-style: none;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
}

.WESContent_fileItem__woC0z {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.WESContent_downloadButton__3q-jY {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.WESContent_downloadButton__3q-jY:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/organoid/NGSPage/WESContent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".wesContent {\n    padding: 20px;\n}\n\n.filterInput {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.fileList {\n    list-style: none;\n    padding: 0;\n    max-height: 300px;\n    overflow-y: auto;\n}\n\n.fileItem {\n    margin-bottom: 10px;\n    display: flex;\n    align-items: center;\n}\n\n.downloadButton {\n    margin-top: 20px;\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.downloadButton:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wesContent": `WESContent_wesContent__t3O9-`,
	"filterInput": `WESContent_filterInput__waPUf`,
	"fileList": `WESContent_fileList__nSSwv`,
	"fileItem": `WESContent_fileItem__woC0z`,
	"downloadButton": `WESContent_downloadButton__3q-jY`
};
export default ___CSS_LOADER_EXPORT___;
